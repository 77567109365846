<template>
  <div
    class="absolute top-28 bg-white h-4/5 transform left-1/2 -translate-x-1/2 w-full lg:w-5/6 xl:w-3/4"
  >
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
